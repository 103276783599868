import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/cryptowander/Maker/test/src/modules/layouts/mdx_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Testing Categories`}</h1>
    <h2>{`This is me testing the Categories component`}</h2>
    <p>{`Hello `}<inlineCode parentName="p">{`world`}</inlineCode></p>
    <p>{`Hello `}<inlineCode parentName="p">{`world?`}</inlineCode></p>
    <p>{`How about `}<inlineCode parentName="p">{`const variable = "Hello World"`}</inlineCode></p>
    <p><inlineCode parentName="p">{`How about this?`}</inlineCode></p>
    <pre><code parentName="pre" {...{}}>{`
Helllo? 

How about this?

Or this?

`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      